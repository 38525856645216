import React from "react";
import { Link } from "gatsby";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import Layout from "../components/layout";
import LetsChat from "../components/letsChat";
import moment from "moment";
import { graphql } from "gatsby";
require("../css/workshops.css");

var tick = "https://res.cloudinary.com/djoztpm50/image/upload/v1559293199/tick_icon_d1yrmm.png";


class Workshops extends React.Component {
  constructor() {
    super();
    this.state = {
      workshop: [],
      editedWorkshopData: []
    };
    // this.openModal = this.openModal.bind(this)
  }
  componentDidMount() {
    var editedworkshopArr = [];

    var data = this.props.data.allWordpressWpWorkshop.edges;
    // console.log("data", data);
    this.setState({ workshop: data }, () => {
      // console.log("this", this.state.workshop);
      this.state.workshop.map(e => {
        //  console.log("title",e.node.title) 
        var date = e.node.acf.event_date;
        var convertDate = date.split("/").reverse().join("/");
        convertDate = new Date(convertDate);
        e.node.convertDate = convertDate; // adding convertDate to node obj
        // e.node.displayDate= convertDate.format("MMM Do YY"); 
        e.node.displayDate = moment(convertDate).format("DD MMM YYYY");
        console.log("displayDate", e.node.displayDate)
        editedworkshopArr.push(e);
      })
      var latestEvents = editedworkshopArr.sort((a, b) =>
        a.node.convertDate.valueOf() - b.node.convertDate.valueOf()).reverse();

      console.log("latestEvents", latestEvents)

      this.setState({ editedWorkshopData: latestEvents }, () => {
        console.log("editedWorkshopData", this.state.editedWorkshopData)
      })
    });
  }


  render() {
    const { editedWorkshopData } = this.state;
    // var days;
    return (
      // const Workshops = () => (
      <Layout>
        <div className="aboutUsBanner">
          <div className="col-sm-12">
            <h3 className="hitWorksHead">Workshops by NativeCircle</h3>
          </div>
        </div>


        <div className="col-sm-12 pad0 workshopDiv">
          <div className="container">
            <div className="col-sm-12 pad0">

              <div className="row">
                {editedWorkshopData.map(e => {
                  return (
                    <div className="col-sm-4">
                      <div className="workshopMain">
                        <a href="#"> <img src={e.node.acf.workshop_image.source_url}
                        /> </a>

                        <a href="#"> <div class="workshop-successInfo">

                          <div className="col-sm-12 pad0">
                            <div className="col-sm-12 flt-lft workshop-tt-fmt">
                              <p style={{ textTransform: "Capitalize", display: "inline-block" }}>{e.node.acf.location}</p> , {e.node.acf.no_of_days == "1" ?
                                <p style={{ display: "inline-block" }}>{e.node.acf.no_of_days} day</p>
                                : <p style={{ display: "inline-block" }}>{e.node.acf.no_of_days} days</p>}, {e.node.displayDate}</div>
                            {/* <div className="col-sm-6 flt-lft workshop-place-plcd">{e.node.acf.location}</div> */}

                          </div>

                          <div className="col-sm-12 flt-lft">
                            <p className="desc-workshop-hds">{e.node.title}</p>
                            <p className="desc-desc-wrkps">{e.node.acf.description}</p>
                          </div>

                          {/* <div className="col-sm-12 flt-lft">
                          <p className="no-of-days">No of Days - {e.node.acf.no_of_days}</p>
                        </div> */}
                        </div> </a>
                      </div>
                    </div>
                  );
                })}
              </div>

            </div>
          </div>
        </div>

      </Layout>
    )
  }
}

export default Workshops;

export const pageQuery = graphql`
query nativecircleworkshop {
  allWordpressWpWorkshop {
    edges {
      node {
        title
       acf {
          no_of_days
          event_date
          description
          location
          workshop_image {
            source_url
          }
        }
      }
    }
  }
}
`;

